<template lang="pug">
	.landing
		div
			div
				Header(@login="goToLogin")
				.landing__main
					.pt-0.landing__container(ref="container")
						Directions
				Footer(v-if="!islocaleRuSystemAndIp")
				FooterRuDomain(v-if="islocaleRuSystemAndIp")

			ModalCookie
</template>

<script>
import { mapActions } from 'vuex';
import { DEVICE } from '@/utils/device';

/*Общий*/
import Header from '@/components/landingPage/Header';
import Directions from '@/components/landingPage/Directions';
import Footer from '@/components/landingPage/Footer';
import FooterRuDomain from '@/components/landingPage/FooterRuDomain';
import ModalCookie from '@/components/Modals/Cookie';

export default {
    name: 'index',
    metaInfo() {
        return {
            titleTemplate: this.islocaleRuSystemAndIp
                ? 'Paywall - монетизация контента, сообществ и нейросетей в Телеграме.'
                : 'Paywall - Content monetization service',
        };
    },
    components: {
        Header,
        Directions,
        Footer,
        FooterRuDomain,
        ModalCookie,
    },
    created() {
        this.viewGeoIpRussia();
    },
    mounted() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), 100);
        }

        this.rememberPageView();
    },
    beforeMount() {
        this.redirectToLoginIfMerchantPath();
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    methods: {
        ...mapActions({
            getGeoIpRussia: 'auth/geoIpRussia',
        }),
        redirectToLoginIfMerchantPath() {
            if (window.location.href.includes('merchant')) {
                if (this.me && this.me.id) return this.$router.replace({ name: 'projects' });

                return this.goToLogin();
            }
        },
        goToLogin(e) {
            if (typeof e === 'string') {
                switch (e) {
                    case 'merchant':
                        window.location.href = this.loginUrl;
                        break;
                    case 'partner':
                        window.location.href = this.loginPartnerUrl;
                        break;
                }
                return;
            }
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            window.location.href = this.loginUrl;
        },
        handleBeforeUnload() {
            window.scrollTo({ top: 0, behavior: 'instant' });
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_landing.style.scss';
</style>
